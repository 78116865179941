/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useClipboard,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import ApiCalls from "api/customAxios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useAlert } from "contexts/toastContext";
import FacebookLogin from "components/globalFunctions/FacebookLogin";
import { getProfile } from "components/globalFunctions/HelperFuntions";
import { useAuth } from "AuthContext";

function Overview() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [candidateData, setCandidateData] = useState({});
  const [userProfile, setUserProfile] = useState(null);
  const { showAlert } = useAlert();
  const { updateUserDetails } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const profileImage = useRef(null);
  useEffect(async () => {
    await ApiCalls.get("/user/viewprofile")
      .then((response) => {
        for (const key in response.cirussResponseData) {
          setValue(`${key}`, response.cirussResponseData[key]);
        }
        setValue(
          `name`,
          response.cirussResponseData["firstName"] +
            (response.cirussResponseData["lastName"]
              ? ` ${response.cirussResponseData["lastName"]}`
              : "")
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const candidateValidationSchema = yup.object().shape({
    name: yup.string().required("Enter your name").max(128, "Max length 128"),
    phone: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only numbers")
      .nullable()
      .max(10, "Max length 10"),
  });
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(candidateValidationSchema),
    mode: "onChange",
    defaultValues: { name: "", phone: "", emailAddress: "", country: "" },
  });
  const handleSubmitCadidate = async (data) => {
    const toSend = {
      firstName: data.name.split(" ").slice(0, -1).join(" ").trim(),
      lastName: data.name.split(" ").pop().trim(),
      phone: parseInt(data.phone),
    };
    await ApiCalls.put("/user/editprofile", toSend)
      .then(async (resp) => {
        showAlert({
          title: "success",
          description: "Profile Updated Sucessfully.",
          status: "success",
        });
        const data = await getProfile();
        updateUserDetails(data?.cirussResponseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openChooseImage = () => {
    profileImage.current.click();
  };

  const changeProfileImage = (event) => {
    const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
    const selected = event.target.files[0];

    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader();
      reader.onloadend = () => setUserProfile(reader.result);
      return reader.readAsDataURL(selected);
    }

    onOpen();
  };
  const list = [
    {
      id: 1,
      name: "placeholder stat",
      value: 32,
      color: "yellow",
    },
  ];
  const value = "https://apple.com/cook";
  const { hasCopied, onCopy } = useClipboard(value);

  const profileUrl = useRef(null);

  useEffect(() => {
    if (hasCopied) {
      profileUrl.current.focus();
      profileUrl.current.select();
    }
  });
  const tabs = ["Account Settings", "Company Settings", "Notifications"];
  const handleResponse = (data) => {
    console.log(data);
  };
  return (
    <Container
      display={{ base: "block", md: "flex" }}
      maxW="container.xl"
      mt={200}
    >
      <Box
        as="aside"
        flex={1}
        mr={{ base: 0, md: 5 }}
        mb={{ base: 5, md: 0 }}
        // bg="white"
        rounded="md"
        borderWidth={1}
        borderColor="brand.light"
        style={{ transform: "translateY(-100px)" }}
      >
        <VStack
          spacing={3}
          py={5}
          borderBottomWidth={1}
          // borderColor="brand.light"
        >
          <Avatar
            size="2xl"
            name="Tim Cook"
            cursor="pointer"
            onClick={openChooseImage}
            src={userProfile ? userProfile : "/img/tim-cook.jpg"}
          >
            <AvatarBadge>
              <svg width="0.4em" fill="currentColor" viewBox="0 0 20 20">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                />
              </svg>
            </AvatarBadge>
          </Avatar>
          <input
            hidden
            type="file"
            ref={profileImage}
            onChange={changeProfileImage}
          />

          <VStack spacing={1}>
            <Heading as="h3" fontSize="xl" color="brand.dark">
              Tim Cook
            </Heading>
            <Text color="brand.gray" fontSize="sm">
              CEO of Apple
            </Text>
          </VStack>
        </VStack>
        <VStack as="ul" spacing={0} listStyleType="none">
          {list.map((item) => (
            <Box
              key={item.id}
              as="li"
              w="full"
              py={3}
              px={5}
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              borderBottomWidth={1}
              borderColor="brand.light"
            >
              <Text color="brand.dark">{item.name}</Text>
              <Text color={`brand.${item.color}`} fontWeight="bold">
                {item.value}
              </Text>
            </Box>
          ))}
        </VStack>
        <VStack as="ul" spacing={0} listStyleType="none">
          <Flex mt="5">
            <FacebookLogin />
          </Flex>
        </VStack>
      </Box>
      <Box
        as="main"
        flex={3}
        d="flex"
        flexDir="column"
        justifyContent="space-between"
        pt={5}
        // bg="white"
        rounded="md"
        borderWidth={1}
        borderColor="gray.200"
        // alignItems="center"
        style={{ transform: "translateY(-100px)" }}
      >
        <Tabs>
          <TabList px={5}>
            {tabs.map((tab) => (
              <Tab
                key={tab}
                mx={3}
                px={0}
                py={3}
                fontWeight="semibold"
                color="brand.cadet"
                borderBottomWidth={1}
                _active={{ bg: "transparent" }}
                _selected={{ color: "brand.dark", borderColor: "brand.blue" }}
              >
                {tab}
              </Tab>
            ))}
          </TabList>
          <TabPanels px={3} mt={5}>
            <TabPanel>
              <form onSubmit={handleSubmit(handleSubmitCadidate)}>
                <Grid
                  templateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                  }}
                  gap={6}
                >
                  <FormControl id="firstName">
                    <FormLabel color={textColor}>Name</FormLabel>
                    <Input
                      isRequired={true}
                      type="text"
                      placeholder="enter your name"
                      color={textColor}
                      name="name"
                      {...register("name")}
                    />
                    {errors.name && (
                      <span style={{ color: "red" }}>
                        {errors.name.message}
                      </span>
                    )}
                  </FormControl>
                  <FormControl id="phoneNumber">
                    <FormLabel color={textColor}>Phone Number</FormLabel>
                    <Input
                      focusBorderColor="brand.blue"
                      type="tel"
                      required={true}
                      placeholder="enter mobile number"
                      color={textColor}
                      {...register("phone")}
                    />
                    {errors.phone && (
                      <span style={{ color: "red" }}>
                        {errors.phone.message}
                      </span>
                    )}
                  </FormControl>
                  <FormControl id="emailAddress">
                    <FormLabel color={textColor}>Email Address</FormLabel>
                    <InputGroup>
                      <Input
                        focusBorderColor="brand.blue"
                        type="email"
                        readOnly={true}
                        placeholder="enter your email address"
                        color={textColor}
                        {...register("email")}
                      />
                      <InputRightElement>
                        <RiCheckboxCircleFill
                          style={{
                            color: "green",
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl id="country">
                    <FormLabel color={textColor}>Country</FormLabel>
                    <Select
                      focusBorderColor="brand.blue"
                      placeholder="Select country"
                      color={textColor}
                      dr
                      {...register("country")}
                    >
                      <option value="america">America</option>
                      <option value="england">England</option>
                      <option value="poland">Poland</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Box mt={5} py={5} px={8}>
                  <Flex justifyContent="center">
                    <Button type="submit" disabled={!isValid}>
                      Update
                    </Button>
                  </Flex>
                </Box>
              </form>
            </TabPanel>
            <TabPanel>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap={6}
              >
                <FormControl id="companyId">
                  <FormLabel color={textColor}>Company ID</FormLabel>
                  <InputGroup>
                    <InputLeftAddon color="gray.500">
                      <svg width="1em" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          fillRule="evenodd"
                          d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </InputLeftAddon>
                    <Input
                      focusBorderColor="brand.blue"
                      type="text"
                      placeholder="apple"
                    />
                  </InputGroup>
                </FormControl>
                <FormControl id="companyName">
                  <FormLabel color={textColor}>Name</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="text"
                    placeholder="Apple"
                  />
                </FormControl>
                <FormControl id="emailCompany">
                  <FormLabel color={textColor}>Email Address</FormLabel>
                  <Input
                    focusBorderColor="brand.blue"
                    type="email"
                    placeholder="info@apple.com"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel color={textColor}>Size</FormLabel>
                  <NumberInput>
                    <NumberInputField placeholder="6000" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
              </Grid>
            </TabPanel>
            <TabPanel>
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <FormLabel
                  htmlFor="notificationEmails"
                  mb={0}
                  cursor="pointer"
                  userSelect="none"
                >
                  Receive notification emails
                </FormLabel>
                <Switch id="notificationEmails" />
              </FormControl>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Container>
  );
}

export default Overview;
