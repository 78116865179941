import {
  Box,
  Button,
  Container,
  Heading,
  Stack,
  StackDivider,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  useColorModeValue,
  Option,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import Card from "components/card/Card.js";
const CreateCampaign = ({ businessData }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [step, setStep] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };
  return (
    <>
      <Stepper
        activeStep={step}
        connectorStateColors={true}
        connectorStyleConfig={{
          completedColor: "#56C3FF",
          activeColor: "#56C3FF",
          disabledColor: "#C2CADD",
          size: "3px",
        }}
        styleConfig={{
          activeBgColor: "#56C3FF",
          completedBgColor: "#56C3FF",
          inactiveBgColor: "#eee",
          activeTextColor: "#111",
          completedTextColor: "#222",
          inactiveTextColor: "#444",
        }}
      >
        <Step label="Children Step 1" />
        <Step label="Children Step 2" />
        <Step label="Children Step 3" />
      </Stepper>
      <Card>
        <Heading as="h2" size="lg" mb={4}>
          Create Ad
        </Heading>
        <FormControl>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Campaign Name
          </FormLabel>
          <Input
            isRequired={true}
            fontSize="md"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Enter campaign name"
            mb="24px"
            fontWeight="500"
            size="lg"
            color={textColorSecondary}
            name="campainName"
            borderRadius="2xl"
          />
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Ad Account Name
          </FormLabel>
          <Select
            isRequired={true}
            fontSize="md"
            ms={{ base: "0px", md: "0px" }}
            placeholder="Enter campaign name"
            mb="24px"
            fontWeight="500"
            size="lg"
            color={textColorSecondary}
            name="accountName"
            borderRadius="2xl"
          >
            <option>test</option>
          </Select>
        </FormControl>
      </Card>
    </>
  );
};

export default CreateCampaign;
