import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdFacebook,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import FacebookDashboard from "views/facebook/default";
import CompleteProfile from "views/auth/profile";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Facebook Dashboard",
    layout: "/admin",
    path: "/facebook",
    icon: <Icon as={MdFacebook} width="20px" height="20px" color="inherit" />,
    component: FacebookDashboard,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    hide: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/signin",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hide: true,
  },
  {
    name: "Create Profile",
    layout: "/auth",
    path: "/create-profile",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: CompleteProfile,
    hide: true,
  },
];

export default routes;
