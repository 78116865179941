import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DefaultAuth from "layouts/auth/Default";
import loginbanner from "assets/img/auth/loginbanner.jpg";
import lottlogin from "assets/img/auth/lottlogin.json";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import * as yup from "yup";
import FacebookLogin from "components/globalFunctions/FacebookLogin";

const CompleteProfile = () => {
  const history = useHistory();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [stage, setStage] = useState(1);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  
  const mobileNumberValidation = yup
    .string()
    .matches(/^[0-9]+$/, "Mobile number must only contain digits")
    .min(10, "Mobile number must be at least 10 digits")
    .required("Mobile number is required");

  const handleSubmit = () => {
    submitMobileNumber();
    return;
  };
  const submitMobileNumber = () => {
    setStage(2);
    return;
  };
  return (
    <DefaultAuth illustrationBackground={loginbanner} animation={lottlogin}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {"Lets get you setup"}
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            {stage == 1
              ? "With your Details"
              : stage === 2
              ? "Connect you business to Facebook"
              : ""}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          {stage == 1 && (
            <FormControl>
              <>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Mobile Number
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="number"
                  placeholder="+91 xxxxx xxxxx"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name="mobileNumber"
                  required={true}
                  maxLength={10}
                  onChange={(e) => {
                    mobileNumberValidation
                      .validate(e.target.value.trim())
                      .then(() => {
                        setMobileNumberError("");
                      })
                      .catch((error) => {
                        setMobileNumberError(error.message);
                      });
                    setMobileNumber(e.target.value);
                  }}
                />
                {mobileNumberError && (
                  <span style={{ color: "red" }}>{mobileNumberError}</span>
                )}
              </>

              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                type="submit"
                mb="24px"
                // isDisabled={}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Next
              </Button>
            </FormControl>
          )}
          {stage === 2 && (
            <FacebookLogin
              onSuccess={() => {
                history.push("/admin/default");
              }}
            />
          )}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default CompleteProfile;
