import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "@chakra-ui/react";
import { MdOutlineFacebook } from "react-icons/md";
import ApiCalls from "api/customAxios";

class FacebookLogin extends React.Component {
  componentDidMount() {
    window.FB && window.FB.XFBML.parse();
  }

  handleFBLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          ApiCalls.post("/facebook/link-business", {
            access_token: response.authResponse.accessToken,
          })
            .then((resp) => {
              console.log(resp);
              this.props.onSuccess();
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          console.error("auth not provided");
        }
      },
      {
        scope:
          "public_profile,email,ads_management,ads_read,business_management,pages_read_engagement,pages_manage_ads,pages_manage_metadata,pages_read_user_content,instagram_basic,instagram_content_publish,instagram_manage_comments,catalog_management,leads_retrieval",
      }
    );
  };

  render() {
    return (
      <Button onClick={this.handleFBLogin}>
        <Icon as={MdOutlineFacebook} mr="2" />
        {"Login with Facebook"}
      </Button>
    );
  }
}

// Define propTypes for the component
FacebookLogin.propTypes = {
  onSuccess: PropTypes.func, // PropTypes validation for success callback
};

export default FacebookLogin;
