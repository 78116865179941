/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import loginbanner from "assets/img/auth/loginbanner.jpg";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import ApiCalls from "api/customAxios";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Loader from "components/loader/Loader";
import lottlogin from "assets/img/auth/lottlogin.json";
import { useAuth } from "AuthContext";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const { updateUserDetails, login } = useAuth();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [logIndata, setLogInData] = React.useState({});
  //email
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  //otp
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  //pasword
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [stage, setStage] = useState();
  const [resetPassword1, setResetPassword1] = useState("");
  const [resetPassword2, setResetPassword2] = useState("");
  const [resetPassword1Error, setResetPassword1Error] = useState("");
  const [resetPassword2Error, setResetPassword2Error] = useState("");
  const [resetPassword1Show, setResetPassword1Show] = useState(false);
  const [resetPassword2Show, setResetPassword2Show] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setStage(1);
  }, []);

  const emailValidation = yup
    .string()
    .required("Email address is required.")
    .email("Invalid email address format.");

  const passwordValidation = yup
    .string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters long.");

  const handleSubmit = () => {
    if (stage == 1) {
      exists();
    } else if (stage == 2) {
      verifyOtp();
    } else if (stage == 3) {
      logIn();
    } else if (stage == 4) {
      signUp();
    } else if (stage == 5) {
      forgotPassword();
    }
  };
  const verifyOtp = () => {
    if (!otp || otp.length < 4) {
      setOtpError("Otp is required");
      return;
    }
    setStage(3);
  };

  const exists = async () => {
    if (!email) {
      setEmailError("Email is required");
      return;
    }
    await ApiCalls.post("/user/validateUser", { email: email })
      .then(async (resp) => {
        if (resp?.exists) {
          setStage(3);
        } else {
          setStage(4);
        }
      })
      .catch((err) => {});
  };

  const getUserProfile = async (token) => {
    try {
      const response = await fetch(
        "https://api.ciruss.in/api/v1.0/user/viewprofile",
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      const userProfile = data?.cirussResponseData;
      login(token, userProfile);
    } catch (error) {
      console.error(error);
    }
  };

  const logIn = async () => {
    try {
      if (!password) {
        setPasswordError("Password is required");
        return;
      }

      setIsLoading(true);

      let data = { email: email, password: password };

      const loginResponse = await ApiCalls.post("/user/login", data);
      localStorage.setItem("token", loginResponse.token);
      const token = loginResponse.token;
      await getUserProfile(token);
      setIsLoading(false);
      history.push("/admin/default");
    } catch (err) {
      if (err?.error == "Password is incorrect") setPasswordError(err?.error);
      setIsLoading(false);
    }
  };

  const forgotPassword = async () => {
    if (!resetPassword1 != !resetPassword2) {
      setResetPassword2Error(`Password dosent match`);
      return;
    }
  };

  const signUp = async () => {
    if (resetPassword1 != resetPassword2) {
      setResetPassword2Error("Password dosent match");
      return;
    }
    await ApiCalls.post("/user/signup", {
      confirmPassword: resetPassword2,
      password: resetPassword1,
      email: email,
    })
      .then((resp) => {
        setStage(2);
      })
      .catch((err) => {});
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DefaultAuth illustrationBackground={loginbanner} animation={lottlogin}>
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "14vh" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Heading color={textColor} fontSize="36px" mb="10px">
                {stage === 1
                  ? "Sign In"
                  : stage === 2
                  ? "Enter OTP"
                  : stage === 3 || stage == 4
                  ? "Enter Password"
                  : "Forgot Password"}
              </Heading>
              <Text
                mb="36px"
                ms="4px"
                color={textColorSecondary}
                fontWeight="400"
                fontSize="md"
              >
                {stage === 1
                  ? "Enter your email!"
                  : stage === 2
                  ? "Enter the OTP sent to your email."
                  : stage === 3 || stage == 4
                  ? "Enter your password."
                  : "Enter and confirm password"}
              </Text>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <FormControl>
                {stage == 1 && (
                  <>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Email<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="email"
                      placeholder="xxx@example.com"
                      mb="24px"
                      fontWeight="500"
                      size="lg"
                      name="email"
                      required={true}
                      onChange={(e) => {
                        emailValidation
                          .validate(e.target.value)
                          .then(() => {
                            setEmailError("");
                          })
                          .catch((error) => {
                            setEmailError(error.message);
                          });
                        setEmail(e.target.value);
                      }}
                    />
                    {emailError && (
                      <span style={{ color: "red" }}>{emailError}</span>
                    )}
                  </>
                )}
                {stage == 2 && (
                  <>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      OTP
                    </FormLabel>
                    <HStack justifyContent="center">
                      <PinInput
                        otp
                        onChange={(e) => {
                          setOtp(e);
                        }}
                      >
                        <PinInputField color={textColor} />
                        <PinInputField color={textColor} />
                        <PinInputField color={textColor} />
                        <PinInputField color={textColor} />
                      </PinInput>
                    </HStack>
                    <Flex
                      justifyContent="space-between"
                      align="center"
                      mb="24px"
                    ></Flex>
                    {otpError && (
                      <span style={{ color: "red" }}>{otpError}</span>
                    )}
                  </>
                )}
                {stage == 3 && (
                  <>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        isRequired={true}
                        fontSize="sm"
                        placeholder="Min. 8 characters"
                        mb="24px"
                        size="lg"
                        type={show ? "text" : "password"}
                        variant="auth"
                        onChange={(e) => {
                          passwordValidation
                            .validate(e.target.value)
                            .then(() => {
                              setPasswordError("");
                            })
                            .catch((error) => {
                              setPasswordError(error.message);
                            });
                          setPassword(e.target.value);
                        }}
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                      >
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                        />
                      </InputRightElement>
                    </InputGroup>
                    {passwordError && (
                      <span style={{ color: "red" }}>{passwordError}</span>
                    )}
                  </>
                )}
                {(stage == 4 || stage == 5) && (
                  <>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        isRequired={true}
                        fontSize="sm"
                        placeholder="Min. 8 characters"
                        mb="24px"
                        size="lg"
                        type={resetPassword1Show ? "text" : "password"}
                        variant="auth"
                        onChange={(e) => {
                          passwordValidation
                            .validate(e.target.value)
                            .then(() => {
                              setResetPassword1Error("");
                            })
                            .catch((error) => {
                              setResetPassword1Error(error.message);
                            });
                          setResetPassword1(e.target.value);
                        }}
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                      >
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={
                            resetPassword1Show
                              ? RiEyeCloseLine
                              : MdOutlineRemoveRedEye
                          }
                          onClick={() => {
                            setResetPassword1Show(!resetPassword1Show);
                          }}
                        />
                      </InputRightElement>
                    </InputGroup>
                    {resetPassword1Error && (
                      <span style={{ color: "red" }}>
                        {resetPassword1Error}
                      </span>
                    )}
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Confirm password<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <InputGroup size="md">
                      <Input
                        isRequired={true}
                        fontSize="sm"
                        placeholder="Min. 8 characters"
                        mb="24px"
                        size="lg"
                        type={resetPassword2Show ? "text" : "password"}
                        variant="auth"
                        onChange={(e) => {
                          passwordValidation
                            .validate(e.target.value)
                            .then(() => {
                              if (resetPassword1 != e.target.value) {
                                setResetPassword2Error(
                                  "Confirm password dosn't match"
                                );
                              } else {
                                setResetPassword2Error("");
                              }
                            })
                            .catch((error) => {
                              setResetPassword2Error(error.message);
                            });
                          setResetPassword2(e.target.value);
                        }}
                      />
                      <InputRightElement
                        display="flex"
                        alignItems="center"
                        mt="4px"
                      >
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={
                            resetPassword2Show
                              ? RiEyeCloseLine
                              : MdOutlineRemoveRedEye
                          }
                          onClick={() => {
                            setResetPassword2Show(!resetPassword2Show);
                          }}
                        />
                      </InputRightElement>
                    </InputGroup>
                    {resetPassword2Error && (
                      <span style={{ color: "red" }}>
                        {resetPassword2Error}
                      </span>
                    )}
                  </>
                )}
                {stage == 3 && (
                  <Flex justifyContent="space-between" align="center" mb="24px">
                    <Text
                      color={textColor}
                      fontSize="sm"
                      w="124px"
                      fontWeight="500"
                      onClick={() => {
                        setStage(5);
                      }}
                      cursor="pointer"
                    >
                      Forgot password?
                    </Text>
                  </Flex>
                )}
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  type="submit"
                  mb="24px"
                  disabled={
                    emailError ||
                    otpError ||
                    passwordError ||
                    resetPassword1Error ||
                    resetPassword2Error
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Continue
                </Button>
              </FormControl>
            </Flex>
          </Flex>
        </DefaultAuth>
      )}
    </>
  );
}

export default SignIn;
