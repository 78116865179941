import React from "react";

// Chakra imports
import { Flex, Image, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import cirrusLogoWhite from "assets/img/cirrusLogoWhite.png";
import cirussLogoColored from "assets/img/cirussLogoColored.png";
export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      <Image
        src={useColorModeValue(cirussLogoColored, cirrusLogoWhite)}
        alt="Logo"
        objectFit="contain"
        h="56px"
        w="175px"
        my="32px"
        color={logoColor}
      />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
