import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { useHistory } from "react-router-dom";
import { AlertProvider } from "contexts/toastContext";
import GlobalAlert from "components/glovalUI/toast";
import { AuthProvider,useAuth } from "AuthContext";


const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />
        )
      }
    />
  );
};


const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <AlertProvider>
        <GlobalAlert />
        <ThemeEditorProvider>
          <AuthProvider>
            <HashRouter>
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <ProtectedRoute path="/admin" component={AdminLayout} />
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/admin" />} 
                />
              </Switch>
            </HashRouter>
          </AuthProvider>
        </ThemeEditorProvider>
      </AlertProvider>
    </ChakraProvider>
  );
};

ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById('root')
);
