import React, { useState, useEffect } from "react";
import {
  ChakraProvider,
  Box,
  Spinner,
  extendTheme,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";

const loadingQuotes = [
  "Loading success... Because your journey deserves the best software.",
  "Unleash the power of progress with our SaaS magic. Loading greatness!",
  "Loading dreams into reality. Your SaaS solution for success is almost ready.",
  "Loading innovation: where every click brings you closer to excellence.",
  "Progress in motion: Loading your business with SaaS wonders.",
  "SaaS loading: Turning potential into performance, one click at a time.",
  "The future is loading, and your SaaS is leading the way.",
  "Loading possibilities: Where your ideas meet our powerful SaaS solutions.",
  "Fueling your future success – SaaS loading, progress unfolding.",
  "Revolutionizing your business world, one loading bar at a time.",
  "Loading brilliance: Because your business deserves SaaS excellence.",
  "SaaS loading: Crafting success stories in progress.",
  "Loading your success journey with seamless SaaS experiences.",
  "Transforming potential into performance, one loading moment at a time.",
  "Loading the future of business: Unleashing the power of SaaS innovation.",
  "Your success is loading, and our SaaS is the engine behind it.",
  "SaaS excellence is loading: Elevate your business to new heights.",
  "In the world of progress, loading is our middle name. SaaS excellence awaits.",
  "Loading success: The SaaS way. Where your goals meet our solutions.",
  "Your success is loading – prepare for a SaaS-powered revolution.",
  "Loading brilliance: Transforming your business landscape with SaaS mastery.",
  "SaaS loading: Because your journey deserves cutting-edge technology.",
  "Loading the future of productivity: Your SaaS journey begins now.",
  "Loading success: Where SaaS meets seamless innovation.",
  "Unlocking potential, one loading bar at a time. SaaS redefined.",
  "Loading success: Your SaaS journey is about to reach its peak.",
  "Progress in motion: Where your goals meet our SaaS solutions.",
  "SaaS loading: A journey towards unparalleled business efficiency.",
  "Loading your path to success: Because SaaS is the key to greatness.",
  "Elevate your business experience: SaaS loading, success pending.",
  "Loading the future of business brilliance. SaaS – where dreams take shape.",
  "Unlocking the potential of tomorrow with SaaS loading today.",
  "Loading innovation: Because your business deserves the best SaaS has to offer.",
  "SaaS loading: Crafting a seamless experience for your business journey.",
  "Loading success: Your SaaS journey begins, bringing excellence to every click.",
  "Revolutionize your business journey: SaaS loading, progress unfolding.",
  "Loading possibilities: Your success story, powered by SaaS innovation.",
  "Transforming potential into performance, one loading moment at a time.",
  "Loading brilliance: Because your business deserves SaaS excellence.",
  "In the realm of progress, loading is our anthem. SaaS mastery is the tune.",
  "Loading success: Elevate your business to new heights with SaaS.",
  "SaaS loading: Because your journey deserves cutting-edge technology.",
  "Loading the future of productivity: Your SaaS journey begins now.",
  "Loading success: Where SaaS meets seamless innovation.",
  "Unlocking potential, one loading bar at a time. SaaS redefined.",
  "Loading success: Your SaaS journey is about to reach its peak.",
  "Progress in motion: Where your goals meet our SaaS solutions.",
  "SaaS loading: A journey towards unparalleled business efficiency.",
  "Loading your path to success: Because SaaS is the key to greatness.",
  "Elevate your business experience: SaaS loading, success pending.",
];

const Loader = () => {  
  const [randomQuote, setRandomQuote] = useState("");
  const textColor = useColorModeValue("navy.700", "white");
  useEffect(() => {
    // Set a random loading quote when the component mounts
    const randomIndex = Math.floor(Math.random() * loadingQuotes.length);
    setRandomQuote(loadingQuotes[randomIndex]);
  }, []);

  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top="0"
      left="0"
      backgroundColor={useColorModeValue("white", "navy.900")}
      zIndex="9999"
      flexDirection="row"
    >
      <Spinner size="md" color={textColor} speed="0.7s" />
      {randomQuote && (
        <Text ml="4" color={textColor}>
          {randomQuote}
        </Text>
      )}
    </Box>
  );
};

export default Loader;
