import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  TabIndicator,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
  Button,
  Icon,
} from "@chakra-ui/react";
import ApiCalls from "api/customAxios";
import React, { useEffect, useState } from "react";
import {
  MdFacebook,
  MdOutlineFacebook,
  MdOutlineLinkOff,
} from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CreateCampaign from "./components/CreateCampaign";
import FacebookLogin from "components/globalFunctions/FacebookLogin";
const FacebookDashboard = ({}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [businessData, setBusinessData] = useState({});
  useEffect(async () => {
    await checkConnection();
    await getBusinessData();
  }, []);
  const history = useHistory();
  const checkConnection = async () => {
    const profileResponse = await ApiCalls.get("/user/viewprofile");
    if (!profileResponse?.cirussResponseData?.isFacebookConnected) {
      onOpen();
    }
  };
  const getBusinessData = async () => {
    if (!isOpen) {
      try {
        const resp = await ApiCalls.get("/facebook/getBusinessDetails");
        console.log(resp);
      } catch (err) {}
    }
  };
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Tabs variant="line" size="lg" defaultIndex={1} isLazy>
          <TabList>
            <Tab isDisabled>Analytics</Tab>
            <Tab>Create Campaign</Tab>
            <Tab isDisabled>Billing</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <p>one!</p>
            </TabPanel>
            <TabPanel>
              <CreateCampaign businessData={businessData}/>
            </TabPanel>
            <TabPanel>
              <p>three!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Modal
        isCentered
        blockScrollOnMount={true}
        isOpen={isOpen}
        onClose={() => {
          history.push("/admin/default");
        }}
      >
        <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="3px" />
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon as={MdOutlineLinkOff} color="red" /> Facebook not connected
          </ModalHeader>

          <ModalBody>
            <Text fontWeight="bold" mb="1rem">
              Please connect to facebook to proceed
            </Text>
          </ModalBody>

          <ModalFooter>
            <FacebookLogin />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FacebookDashboard;
