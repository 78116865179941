import axios from "axios";
import { redirectToSignIn } from "components/globalFunctions/HelperFuntions";

let baseURL = "https://api.ciruss.in/api/v1.0/";

const api = axios.create({
  baseURL,
  timeout: 60000,
});
api.interceptors.request.use(
  async (config) => {
    if (
      config.url.includes("/login") ||
      config.url.includes("/signup") ||
      config.url.includes("/send-verification-email")
    ) {
      return config;
    }
    if (localStorage.getItem("token")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (resp) {
    return resp;
  },
  function (error) {
    if (error.response.status === 401) {
      redirectToSignIn();
    }
    return Promise.reject(error);
  }
);

export default class ApiCalls {
  static async get(url) {
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      return Promise.reject(handleError(error));
    }
  }

  static async post(url, payload) {
    try {
      const response = await api.post(url, payload);
      return response.data;
    } catch (error) {
      return Promise.reject(handleError(error));
    }
  }

  static async put(url, payload) {
    try {
      const response = await api.put(url, payload);
      return response.data;
    } catch (error) {
      return Promise.reject(handleError(error));
    }
  }

  static async delete(url, payload) {
    try {
      const response = await api.delete(url, payload);
      return response.data;
    } catch (error) {
      return Promise.reject(handleError(error));
    }
  }
}

function handleError(error) {
  if (error.response) {
    console.error("Error data:", error.response.data);
    return error.response.data;
  } else if (error.request) {
    console.error("No response received");
    return "No response received";
  } else {
    console.error("Error message:", error.message);
    return error.message;
  }
}
