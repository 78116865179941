/* eslint-disable */

import { useHistory } from "react-router-dom";
import React from "react";
import ApiCalls from "api/customAxios";

const redirectToSignIn = () => {
  const history = useHistory();
  localStorage.clear();
  history.push("/auth/signin");
};

const getProfile = async () => {
  if (localStorage.getItem("token"))
    return await ApiCalls.get("/user/viewprofile");
};

export { redirectToSignIn, getProfile };
